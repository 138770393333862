import * as React from 'react';
import Helmet from 'react-helmet';

import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { BlockText } from 'components/block-text/BlockText';

export default () => (
  <>
    <Helmet title="Electrical Contractors" />

    <Intro>
      Electrical Installation and Maintenance <Highlight>Contractors</Highlight>.
    </Intro>

    <BlockText
      heading="Who we are"
      description={(
        <>
          We are an expert team of electrical contractors specialising in High and
          Low voltage systems. Installation, Termination, Jointing, Containment Systems.
          <br />
          <br />
          Call us: 07766 810273
        </>
      )}
    />
  </>
);
